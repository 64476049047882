<template>
  <div class="select-card">
    <div class="select-card-top">
      <div class="title">成本概况</div>
    </div>
    <div class="select-card-bottom">
      <div class="select-card-bottom-left">
        <!-- 数据维度 -->
        <el-cascader
          collapse-tags
          v-model="select_data_tree"
          :options="data_tree"
          :props="{
            multiple: true,
            label: 'deptName',
            value: 'deptIdOrOaAccount',
            checkStrictly: true,
          }"
          placeholder="请选择组织架构"
          size="small"
          style="min-width: 160px"
          ref="myCascader"
          filterable
          show-all-levels
        >
          <template #default="{ data }">
            <span>{{ data.deptName }}</span>
          </template>
        </el-cascader>
        <!-- 产品线 -->
        <el-cascader
          collapse-tags
          v-model="selectClass"
          :options="productClass"
          :props="{ multiple: true, label: 'name', value: 'typeId' }"
          placeholder="请选择产品线"
          size="small"
          
          style="min-width: 160px; margin-left: 12px"
          filterable
        >
          <template #default="{ data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
        <el-cascader
          collapse-tags
          v-model="doctorIds"
          :options="doctors"
          :props="cascaderProps"
          placeholder="请选择客户"
          size="small"
          style="min-width: 120px; margin-left: 12px"
        >
          <template #default="{ data }">
            <span>{{ data.regionName }}</span>
          </template>
        </el-cascader>
      </div>
      <div class="select-card-bottom-right">
        <span style="color:#3BAF61;font-size:16px;">月</span>
        <!-- <el-select
          v-model="timeType"
          placeholder="请选择"
          size="small"
          style="width: 100px; margin-left: 12px"
        >
          <el-option
            v-for="item in dateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <div v-show="timeType == 3">
          <el-date-picker
            class="select-day"
            style="margin-left: 20px; margin-right: 20px"
            format="yyyy-MM"
            v-model="timeMonth"
            type="monthrange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions3"
            size="small"
            @change="checkTimeType()"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          @click="querySelect"
          :loading="isLoading"
          >查询</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus";
export default {
  data() {
    return {
      isTax: 2,
      downType: "",
      isTaxCheck: false,
      testValue: [],
      cascaderProps: {
        multiple: true,
        label: "regionName",
        value: "regionCode",
        lazy: true,
        checkStrictly: true,
        lazyLoad: this.lazyLoad,
      },
      downOptions: [
        {
          value: 1,
          label: "大区目标",
        },
        {
          value: 2,
          label: "人的目标",
        },
        {
          value: 3,
          label: "产品线目标",
        },
      ],
      dateOptions: [
        {
          value: 3,
          label: "月",
        },
      ],
      // 月
      pickerOptions3: {
        disabledDate(date) {
          let t = new Date().getDate();
          return date.getTime() > Date.now() - 8.64e7 * t;
        },
        shortcuts: [
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      select_data_tree: "", // 选中的数据维度权限
      data_tree: [], // 数据维度树
      productClass: [], // 产品类目列表
      selectClass: [], // 选中的类目
      timeType: 3, // 日期类型
      timeDay: "", // 选中日
      timeWeekStart: "", // 选中周
      timeWeekEnd: "",
      timeMonth: "", // 选中月
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      week1: "", // 本月第几周
      week2: "", // 本月第几周
      isLoading: false,
      unSelectDept: [], //未选中的架构
      levelArr: [], //
      doctors: [],
      doctorIds: [],
      changeType:false
    };
  },
  created() {
    // var tMonth = new Date().getMonth() + 1;
    //  获取交易概览选择列表
    this.axios.post("TRANSACTION_LIST",{type:2}).then((result) => {
      // 重新组装结构
      let data_tree = [];
      if ("专员直单" in result.data.team) {
        data_tree.push({
          deptIdOrOaAccount: 1,
          deptName: "专员直单",
          children: result.data.team["专员直单"],
        });
      }
      if ("自然人" in result.data.team) {
        data_tree.push({
          deptIdOrOaAccount: 2,
          deptName: "自然人",
        });
      }
      if ("经理直单" in result.data.team) {
        data_tree.push({
          deptIdOrOaAccount: 3,
           deptName: "经理直单",
        });
      }
      this.data_tree = data_tree;
      this.productClass = result.data.goodsType;
      this.doctors = result.data.doctors;
    });
    this.defaultDate()
    // 默认调用一下
    this.querySelect();
  },
  methods: {
     defaultDate () {
      //获取新的时间
      let date = new Date()
      //获取当前时间的年份转为字符串
      let year = date.getFullYear().toString()
      //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
      let month = date.getMonth()  < 10 ? '0' + (date.getMonth()).toString() : (date.getMonth()).toString()
      //获取天，判断是否小于10，如果是在字符串前面拼接'0'
      let da = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString()
      //字符串拼接，开始时间，结束时间
      let end = year + '-' + month  //当天
      let beg = year + '-' + month    //当月第一天
      this.timeMonth = [beg, end] //将值设置给插件绑定的数据
    },
    lazyLoad(node, resolve) {
      // console.log("lazyLoad", node);
      this.getData(node, resolve);
    },
    async getData(node, resolve) {
      let that = this;
      let { level, value } = node;
      // console.log(node);
      let params = {
        levelType: parseInt(level + 1),
        regionCode: value,
        type: 1,
      };
      if (!node.data) {
        // console.log("hdjshdjshdhjsdhds");
        let test = [];
        await this.axios
          .post("HospitalOrDoctor", params)
          .then((result) => {
            // console.log(result.data);
            test = result.data;
          })
          .catch(() => {
            this.isLoading = false;
            // this.$store.dispatch("app/setLoading", false);
          });
        setTimeout(() => {
          const nodes = Array.from(test).map((item) => ({
            value: item.regionCode,
            label: `${item.regionName}`,
            leaf: level >= 3,
            // level
          }));

          // console.log(nodes);
          resolve([...nodes]);
          // this.$forceUpdate()
        }, 500);
      } else {
        let test = [];
        await this.axios
          .post("HospitalOrDoctor", params)
          .then((result) => {
            test = result.data;
            // console.log(test, "test");
            setTimeout(() => {
              const nodes = Array.from(test).map((item) => {
                return {
                  regionCode: item.regionCode,
                  regionName: `${item.regionName}`,
                  leaf: level >= 3,
                  // level
                };
              });
              resolve(nodes);
              // console.log(node.children, "eeee");
            }, 500);
          })
          .catch(() => {
            this.isLoading = false;
            // this.$store.dispatch("app/setLoading", false);
          });
      }
    },
    // 切换日期类型
    checkTimeType(index) {
      this.changeType=true
      // 广播出去
      // Bus.$emit("overviewTimeType", this.timeType);
    },
    // 查询数据
    querySelect() {
      if (this.timeType == 3) {
        if (this.timeMonth&&this.changeType) {
          // console.log(this.timeMonth);
          // 年
          let year0 = this.timeMonth[0].getFullYear();
          // 月
          let month0 = this.timeMonth[0].getMonth() + 1;
          // 年
          let year1 = this.timeMonth[1].getFullYear();
          // 月
          let month1 = this.timeMonth[1].getMonth() + 1;
          this.startTime = `${year0}${month0 < 10 ? "0" + month0 : month0}`;
          this.endTime = `${year1}${month1 < 10 ? "0" + month1 : month1}`;
        } else {
          this.startTime =parseInt(this.timeMonth[0].replace(/\-/g,""))
          this.endTime = parseInt(this.timeMonth[1].replace(/\-/g,""))
        }
      }
      // console.log("开始时间", this.startTime);
      // this.isLoading = true;
      // this.$store.dispatch("app/setLoading", true);
      // 数据请求

      let params = {
        dateType: this.timeType,
        memberIds: this.select_data_tree,
        productLineTypes: this.selectClass,
        startTime: this.startTime,
        endTime: this.endTime,
        isTax: this.isTax,
        doctorIds: this.doctorIds,
      };
      let teams = [],
        departOneList = [],
        departTwoList = [],
        departThreeList = [],
        areaList=[],
        cityList=[],
        provinceList = [],
        hospitalIdList = []
        ;
      let jgData = [teams, departOneList, departTwoList, departThreeList];
      let customData = [provinceList, cityList, areaList,hospitalIdList];
      let productLinesData =[]
      // console.log(this.select_data_tree, data);
      if (this.select_data_tree.length > 0) {
        this.select_data_tree.forEach((element) => {
          element.forEach((child, c_index) => {
            jgData[c_index].push(child);
          });
        });
      }
      if (this.doctorIds.length > 0) {
        this.doctorIds.forEach((element) => {
          element.forEach((child, c_index) => {
            customData[c_index].push(child);
          });
        });
      }
      if (this.selectClass.length > 0) {
        this.selectClass.forEach((element) => {
          element.forEach((child, c_index) => {
            if(c_index==0){
             productLinesData.push(child);
            }
            
          });
        });
      }
      let param = {
        teams:jgData[0].length>0?jgData[0]:[],
        departOneList:jgData[1].length>0?jgData[1]:[],
        departTwoList:jgData[2].length>0?jgData[2]:[],
        departThreeList:jgData[3].length>0?jgData[3]:[],
        provinceList:customData[0].length>0?customData[0]:[],
        cityList:customData[1].length>0?customData[1]:[],
        areaList:customData[2].length>0?customData[2]:[],
        hospitalIdList:customData[3].length>0?customData[3]:[],
         productLines: productLinesData.length > 0 ? productLinesData : [],
        startMonth: this.startTime,
        endMonth: this.endTime,
        

      };
      // for (var key in param) {
      //   if(key!="startMonth"&&key!='endMonth'){
      //     let element = param[key]
      //     console.log(element)

      //     if(element.length==0){
      //       delete param[key]
      //     }

      //   }
      // }
      // console.log(param)

      // return;
      this.axios
        .post("FINANCE_COSTSUM", param)
        .then((result) => {
          if(result.retCode=='200'){
            this.$store.dispatch("data/setTotalDataCost", result.data);
          }else{
            this.$message.error(result.msg);
          }
          
          // result.data.timeType = this.timeType;
          this.$store.dispatch("data/setTotalDataCost", result.data);
          // this.isLoading = false;
          // this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          // this.$store.dispatch("app/setLoading", false);
        });
    },
    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
  },
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .btnClass {
    width: 100%;
    height: 28px;
    background: rgba(0, 178, 88, 0.1);
    border-radius: 16px;
    color: #00b258;
    border: none;
    text-align: center;
    span {
      display: inline-block;
      width: 100%;
      line-height: 28px;
      text-align: center;
    }
  }
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      flex: 1;
    }
    /deep/ .el-checkbox__label {
      display: inline-block;
      padding-left: 10px;
      line-height: 19px;
      font-size: 12px;
    }
    .check_con {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      line-height: 17px;
      letter-spacing: 1px;
      margin-right: 24px;
    }
    .btn {
      // margin-right: 12px;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
<template>
  <div class="select-card">
    <div class="select-card-top">
      <div>组织架构</div>
      <div style="flex: 1">
        <div class="select-card-bottom">
          <div class="select-card-bottom-left">
            <!-- 数据维度 -->
            <el-cascader
              collapse-tags
              v-model="select_data_tree"
              :options="data_tree"
              :props="{
                multiple: true,
                label: 'deptName',
                value: 'deptIdOrOaAccount',
                checkStrictly: true,
              }"
              placeholder="组织架构"
              size="small"
              style="min-width: 240px"
            >
              <template #default="{ data }">
                <span>{{ data.deptName }}</span>
              </template>
            </el-cascader>
            <el-button
              type="primary"
              size="mini"
              @click="querySelect(1)"
              :loading="isLoading"
              style="margin-left: 24px"
              >查询</el-button
            >
          </div>
        </div>
      </div>
      <ul class="tabs-con">
        <li
          v-for="(i, index) in tabs"
          :key="index"
          :class="tabsIndex == i.type ? 'li-active' : ''"
          @click="tabClick(index)"
        >
          {{ i.name }}
        </li>
      </ul>
    </div>
    <div class="select-card-bottom">
      <ChartLine2
        v-if="tabsIndex == 3 && isFlag"
        :id="1"
        :tissueComparison="tissueComparison"
        :key="random"
      />
      <Bar
        v-if="tabsIndex == 2 && isFlag"
        :tissueComparison="tissueComparison"
        :id="1"
        :key="random"
      />
      <LineView
        v-if="tabsIndex == 1 && isFlag"
        :tissueComparison="tissueComparison"
        :id="1"
        :key="random"
      />
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus";
import ChartLine2 from "./chart_line_2.vue";
import Bar from "./bar";
import LineView from "./lineview";
import get_jg_list from "@/utils/jg";
export default {
  components: {
    ChartLine2,
    Bar,
    LineView,
  },
  props: ["chartType", "date"],
  data() {
    return {
      random: 0,
      dateParam: {},
      tabs: [
        {
          name: "柱状图",
          type: 1,
        },
        {
          name: "分布图",
          type: 2,
        },
        {
          name: "趋势图",
          type: 3,
        },
      ],
      tabsIndex: 1,
      select_data_tree: [], // 选中的数据维度权限
      data_tree: [], // 数据维度树
      goodsType: [], // 产品类目列表
      selectClass: [], // 选中的类目
      isLoading: false,
      isFlag: "",
      startTime: "",
      endTime: "",
    };
  },
  created() {
    console.log("组织架构-成本", this.date);
    this.startTime = this.date.startTime;
    this.endTime = this.date.endTime;
    this.querySelect();
    this.init();
  },
  methods: {
    async init() {
      let { data_tree, goodsType } = await get_jg_list.get_jg_list(2);
      this.data_tree = data_tree;
      this.goodsType = goodsType;
      console.log(goodsType);

      // console.log(this.data_tree);
      // console.log("组织架构-对比");
    },
    tabClick(val) {
      // console.log(val);
      this.tabsIndex = val + 1;
    },
    // 根据日期判断是月的第几周

    // 查询数据
    querySelect() {
      this.isLoading = true;
      // this.$store.dispatch("app/setLoading", true);
      let params = {},
        url = "FINANCE_DEPTCOST";

      let teams = [],
        departOneList = [],
        departTwoList = [],
        departThreeList = [];
      let jgData = [teams, departOneList, departTwoList, departThreeList];
      // console.log(this.select_data_tree, data);
      if (this.select_data_tree.length > 0) {
        this.select_data_tree.forEach((element) => {
          element.forEach((child, c_index) => {
            jgData[c_index].push(child);
          });
        });
      }
      console.log(jgData);
      params = {
        areaList: [],
        cityList: [],
        costTypes: [],
        departOneList: jgData[1].length > 0 ? [...new Set(jgData[1])] : [],
        departThreeList: jgData[3].length > 0 ? [...new Set(jgData[3])] : [],
        departTwoList: jgData[2].length > 0 ? [...new Set(jgData[2])] : [],
        startMonth: this.startTime,
        hospitalIdList: [],
        platformCodes: [],
        processCodes: [],
        productLines: [],
        provinceList: [],
        queryAreas: [],
        endMonth: this.endTime,
        teams: jgData[0].length > 0 ? [...new Set(jgData[0])] : [],
      };
      // 数据请求
      this.axios
        .post(url, params)
        .then((result) => {
          console.log("成本-对比分析组织架构-查询返回", result);
          if (result.retCode == "200") {
            this.isFlag = true;
            this.tissueComparison = result.data;
          } else {
            this.$message.error(result.msg);
          }

          this.isLoading = false;
          this.random = Math.random();
          // this.$store.dispatch("app/setLoading", false);
          // this.$store.dispatch("data/setTotalData", result.data);
        })
        .catch(() => {
          this.isLoading = false;
          // this.$store.dispatch("app/setLoading", false);
        });
    },
    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
  },
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;

  border-bottom-left-radius: none;
  border-bottom-right-radius: none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  margin-top: 24px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .tabs-con {
      min-width: 200px;
      display: flex;
      flex-direction: row;
      li {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-left: 16px;
      }
      .li-active {
        color: #00b54b;
        border-bottom: 2px solid #00b54b;
        text-align: center;
        font-weight: 700;
      }
    }
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
<template>
  <div class="select-card-child">
    <div
      class="select-card-bottom"
      v-watermark="{ text: text, textColor: 'rgba(180, 180, 180, 0.3)' }"
    >
      <div
        :id="'myChart3' + id"
        :style="{ width: '100%', height: '450px' }"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import getRandomColor from "@/utils/jg";
import colors from "@/utils/color";
export default {
  data() {
    return {
      xAxisData: [],
      yAxisData: [],
      legendData: [],
      seriesData: [],
      lineColor: [],
      itemStyle: {
        color: "",
      },
      text: "",
    };
  },
  props: ["id", "tissueComparison"],
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.text = JSON.parse(sessionStorage.getItem("userInfo")).name;
    }
    console.log("成本对比分析-饼图", this.tissueComparison);
    this.legendData = this.tissueComparison.chartLabels;
    this.xAxisData = this.tissueComparison.dateList;
    this.lineColor = colors;
    for (let i in this.tissueComparison.chartLabels) {
      if (i > colors.length - 1) {
        let random_color = getRandomColor.getRandomColor();
        this.lineColor.push(random_color);
      }
    }
    this.tissueComparison.chartLabels.forEach((element, index) => {
      let temp = {};
      temp.value = this.tissueComparison.sumDataList[index];
      // temp.rate = this.tissueComparison.[index];
      temp.name = element;
      temp.color = this.lineColor[index];
      this.seriesData.unshift(temp);
    });
    console.log(this.legendData);
    console.log(this.seriesData);
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart3" + this.id));
      // 绘制图表
      var option;
      option = {
        dataGroupId: "",
        animationDurationUpdate: 500,
        // itemWidth: 14,
        legend: {
          left: "left",
          itemWidth: 12,
          itemHeight: 12,
          type: "scroll",
          orient: "horizontal",
          itemGap: 24,
          icon: "roundRect",
        },
        tooltip: {
          show: true,
          backgroundColor: "#fff",
          trigger: "item",
          extraCssText: "box-shadow: 0px 2px 12px 0px rgba(64, 98, 117, 0.1);",
          padding: 12,
          textStyle: {
            color: "#262626",
            fontSize: "12",
          },
          formatter: function (param) {
            console.log(param);
            var lightTypeList = param.data.value;
            //拼接一个字符串
            // +"占比："+param.data.rate*100+"%"
            var res = `<span style="display:inline-block;width:10px;height:10px;background:${param.color};
border-radius: 2px;margin-right:4px;"></span>${param.data.name}<br/>`;
            //循环遍历内容
            res +=
              "实际成本额：" +
              lightTypeList +
              "<span style='margin-left:4px;text-align:right'>万</span>" +
              "<br/>";
            return res;
          },
        },
        // tooltip: {},
        color: this.lineColor,
        series: {
          type: "pie",
          id: "sales",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              // show: true,
              // fontSize: "16",
              // fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: this.seriesData,
          universalTransition: {
            enabled: true,
            divideShape: "clone",
          },
        },
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.select-card-child {
  background: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: none;
  border-top-left-radius: none;
  width: 100%;
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    width: 100%;
    min-height: 500px;
  }
}
</style>

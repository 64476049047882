<template>
  <div class="select-card">
    <div class="select-card-top">
      <div>客户群体</div>
      <div style="flex: 1">
        <div class="select-card-bottom">
          <div class="select-card-bottom-left">
            <!-- 数据维度 -->
            <el-cascader
              collapse-tags
              v-model="doctorIds"
              :options="doctors"
              :props="cascaderProps"
              placeholder="请选择客户"
              size="small"
              style="min-width: 120px"
            >
              <template #default="{ data }">
                <span>{{ data.regionName }}</span>
              </template>
            </el-cascader>
            <el-button
              type="primary"
              size="mini"
              style="margin-left: 24px"
              @click="querySelect"
              >查询</el-button
            >
          </div>
        </div>
        <!-- :loading="isLoading" -->
      </div>
      <ul class="tabs-con" v-if="isFlag && bar == 1">
        <li
          v-for="(i, index) in tabs"
          :key="index"
          :class="tabsIndex == i.type ? 'li-active' : ''"
          @click="tabClick(i.type)"
        >
          {{ i.name }}
        </li>
      </ul>
    </div>
    <Map :tissueComparison="tissueComparison" v-if="isFlag && bar == 0" />

    <Bar
      v-if="isFlag && bar == 1"
      :tissueComparison="tissueComparison"
      :id="4 + 'lin6'"
      :key="random"
    />
    <LineView
      v-if="isFlag && bar == 1 && tabsIndex == 1"
      :tissueComparison="tissueComparison"
      :id="3 + 'line6'"
      :key="random"
    />
  </div>
</template>

<script>
import * as echarts from "echarts";
import get_jg_list from "@/utils/jg";
import Bus from "@/utils/bus";
import china from "@/assets/json/china.json";
echarts.registerMap("china", china);
import Map from "../components/map";
import Bar from "../components/bar";
import LineView from "../components/lineview";

export default {
  name: "index",
  props: ["date"],
  components: {
    Map,
    Bar,
    LineView,
  },
  data() {
    return {
      random: 0,
      doctors: [],
      doctorIds: [],
      bar: 0,
      yData: [],
      isFlag: "",
      cascaderProps: {
        multiple: true,
        label: "regionName",
        value: "regionCode",
        lazy: true,
        checkStrictly: true,
        lazyLoad: this.lazyLoad,
      },
      tabs: [
        {
          name: "柱状图",
          type: 1,
        },
        {
          name: "分布图",
          type: 2,
        },
      ],
      tabsIndex: 1,
    };
  },
  created() {
    this.startTime = this.date.startTime;
    this.endTime = this.date.endTime;
    this.bar = 0;
    this.doctorIds = [];
    this.querySelect();
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      this.setMapData();
    });
  },
  methods: {
    tabClick(val) {
      // console.log(val);
      this.tabsIndex = val;
    },
    async init() {
      let { doctors } = await get_jg_list.get_jg_list(2);
      this.doctors = doctors;

      // return this.this.yData
    },
    lazyLoad(node, resolve) {
      this.getData(node, resolve);
    },
    async getData(node, resolve) {
      let that = this;
      let { level, value } = node;
      let params = {
        levelType: parseInt(level + 1),
        regionCode: value,
        type: 1,
      };
      if (!node.data) {
        let test = [];
        await this.axios
          .post("HospitalOrDoctor", params)
          .then((result) => {
            if (result.retCode == "200") {
              test = result.data;
              setTimeout(() => {
                const nodes = Array.from(test).map((item) => ({
                  value: item.regionCode,
                  label: `${item.regionName}`,
                  leaf: level >= 3,
                }));
                resolve([...nodes]);
              }, 500);
            } else {
              this.$message.error(result.msg);
            }
          })
          .catch(() => {
            this.isLoading = false;
            // this.$store.dispatch("app/setLoading", false);
          });
      } else {
        let test = [];
        await this.axios
          .post("HospitalOrDoctor", params)
          .then((result) => {
            if (result.retCode == "200") {
              test = result.data;
              setTimeout(() => {
                const nodes = Array.from(test).map((item) => {
                  return {
                    regionCode: item.regionCode,
                    regionName: `${item.regionName}`,
                    leaf: level >= 3,
                    // level
                  };
                });
                resolve(nodes);
              }, 500);
            } else {
              this.$message.error(result.msg);
            }
          })
          .catch(() => {
            this.isLoading = false;
            // this.$store.dispatch("app/setLoading", false);
          });
      }
    },
    // 查询数据
    querySelect() {
      //type:1 组织架构 2:产品线
      this.isLoading = true;
      // this.$store.dispatch("app/setLoading", true);
      let params = {},
        url = "FINANCE_CUSTOMCOST";
      let provinceList = [],
        cityList = [],
        areaList = [],
        hospitalIdList = [];
      let customData = [provinceList, cityList, areaList, hospitalIdList];

      if (this.doctorIds.length > 0) {
        this.doctorIds.forEach((element) => {
          element.forEach((child, c_index) => {
            customData[c_index].push(child);
          });
        });
      }
      params = {
        areaList: customData[2].length > 0 ? [...new Set(customData[2])] : [],
        cityList: customData[1].length > 0 ? [...new Set(customData[1])] : [],
        startMonth: parseInt(this.startTime),
        hospitalIdList:
          customData[3].length > 0 ? [...new Set(customData[3])] : [],
        provinceList:
          customData[0].length > 0 ? [...new Set(customData[0])] : [],
        endMonth: parseInt(this.endTime),
      };
      // 数据请求
      this.axios
        .post(url, params)
        .then((result) => {
          if (result.retCode == "200") {
            this.tissueComparison = result.data;
            if (params.provinceList.length > 0) {
              this.bar = 1;
              this.tabsIndex = 1;
            } else {
              this.bar = 0;
            }
            this.isFlag = true;
            this.random = Math.random();
            let chartLabels = this.tissueComparison.chartLabels;
            let sumDataList = this.tissueComparison.sumDataList;
            let trendDataList = this.tissueComparison.trendDataList;
            this.yData = [];
            chartLabels.forEach((element, index) => {
              let temp = {};
              temp.name = element;
              temp.value = sumDataList[index];
              temp.perf = trendDataList[index];
              this.yData.push(temp);
            });
          } else {
            this.$message.error(result.msg);
          }

          this.isLoading = false;
          // this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          // this.$store.dispatch("app/setLoading", false);
        });
    },
    // 地图
    setMapData() {
      // 初始化echarts实例 #D8E9FD
      this.chinachart = echarts.init(document.getElementById("china_map"));
      // 进行相关配置
      this.chartOption = {
        tooltip: {
          padding: 12,
          extraCssText: "box-shadow: 0px 2px 12px 0px rgba(64, 98, 117, 0.1);",
          // 鼠标移到图里面的浮动提示框
          // formatter详细配置： https://echarts.baidu.com/option.html#tooltip.formatter
          formatter(params) {
            // params.data 就是series配置项中的data数据遍历

            let localValue, perf;
            if (params.data) {
              localValue = params.data.value;
              perf = params.data.perf;
              //   downloadSpeep = params.data.downloadSpeep;
              //   usability = params.data.usability;
              //   point = params.data.point;
            } else {
              // 为了防止没有定义数据的时候报错写的
              localValue = 0;
              perf = 0;
            }
            let htmlStr = `
          <div style='font-size:12px;'> ${params.name}</div>
          <p style='text-align:left;margin-top:-4px;'>
              实际销售额：${localValue}<br/>
              占比：${perf}<br/>
          </p>
        `;
            return htmlStr;
          },
          backgroundColor: "#ffffff", //提示标签背景颜色
          textStyle: { color: "#262626" }, //提示标签字体颜色
        },
        // visualMap: {
        //   show: true,
        //   bottom: 60,
        //   right: 200,
        //   text: ["高", "低"],
        //   //  min:0,
        //   //   precision:0,
        //   itemHeight: 200,
        //   color: [
        //     "#5475f5",
        //     "#9feaa5",
        //     "#85daef",
        //     "#74e2ca",
        //     "#e6ac53",
        //     "#9fb5ea",
        //   ],
        // },
        // geo配置详解： https://echarts.baidu.com/option.html#geo
        geo: {
          // 地理坐标系组件用于地图的绘制
          map: "china", // 表示中国地图
          // roam: true, // 是否开启鼠标缩放和平移漫游
          zoom: 1.2, // 当前视角的缩放比例（地图的放大比例）
          label: {
            // show: true
          },
          itemStyle: {
            // 地图区域的多边形 图形样式。

            normal: {
              //未选中的状态
              borderColor: "#ffffff",
              areaColor: "#D8E9FD", //背景颜色
              label: {
                // show: true, //显示名称
              },
            },
            emphasis: {
              //选中的状态
              // 高亮状态下的多边形和标签样式
              shadowBlur: 20,
              areaColor: "#00B585",
              shadowColor: "rgba(0, 0, 0, 0.5)",
              borderColor: "#fff",
            },
            select: {
              label: {
                color: "#fff",
              },
            },
          },
        },
        series: [
          {
            name: "地图", // 浮动框的标题（上面的formatter自定义了提示框数据，所以这里可不写）
            type: "map",
            geoIndex: 0,
            label: {
              show: true,
            },
            // 这是需要配置地图上的某个地区的数据，根据后台的返回的数据进行拼接（下面是我定义的假数据）
            data: this.yData,
            // data:[{
            //     name: "北京",
            //     value: 599, //  项目数量
            //     perf: "60%", // 项目占比
            //   },
            //   {
            //     name: "上海",
            //     value: 142,
            //     perf: "40%",
            //   },
            //   {
            //     name: "黑龙江",
            //     value: 44,
            //     perf: "1%",
            //   },
            //   {
            //     name: "新疆",
            //     value: 999,
            //     perf: "70%",
            //   },]
          },
        ],
        dataList: this.yData,
      };
      // 使用刚指定的配置项和数据显示地图数据
      this.chinachart.setOption(this.chartOption);
    },
  },
};
</script>
<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    width: 100%;
    min-height: 500px;
  }
  .tabs-con {
    min-width: 100px;
    display: flex;
    flex-direction: row;
    li {
      flex: 1;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-left: 16px;
    }
    .li-active {
      color: #00b54b;
      border-bottom: 2px solid #00b54b;
      text-align: center;
      font-weight: 700;
    }
  }
}
</style>
<style scoped>
#china_map_box {
  height: 100%;
  width: 100%;
  position: relative;
}
#china_map_box #china_map {
  height: 100%;
}
#china_map_box .china_map_logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
}
</style>
<style>
#china_map .tooltip_style {
  line-height: 1.7;
  overflow: hidden;
}
#china_map .tooltip_left {
  float: left;
}
#china_map .tooltip_right {
  float: right;
}
</style>

<template>
  <div class="select-card">
    <div class="select-card-top">
      <div>平台/流程成本</div>
      <div style="flex: 1">
        <div class="select-card-bottom">
          <div class="select-card-bottom-left">
            <!-- 平台流程分类 -->
            <el-cascader
              collapse-tags
              v-model="selectClass"
              :options="processList"
              :props="cascaderProps"
              placeholder="请选择平台/流程"
              size="small"
              style="min-width: 120px"
            >
              <template #default="{ data }">
                <span>{{ data.name }}</span>
              </template>
            </el-cascader>
          </div>
          <div class="select-card-bottom-left" style="margin-left: 20px">
            <el-button type="primary" size="mini" @click="querySelect(2)"
              >查询</el-button
            >
          </div>
        </div>
      </div>
      <ul class="tabs-con">
        <li
          v-for="(i, index) in tabs"
          :key="index"
          :class="tabsIndex == i.type ? 'li-active' : ''"
          @click="tabClick(index)"
        >
          {{ i.name }}
        </li>
      </ul>
    </div>

    <div class="select-card-bottom">
      <ChartLine2
        v-if="tabsIndex == 3 && isFlag"
        :id="4"
        :tissueComparison="tissueComparison"
        :key="random"
      />
      <Bar
        v-if="tabsIndex == 2 && isFlag"
        :tissueComparison="tissueComparison"
        :id="4+'bar5'"
        :key="random"
      />
      <LineView
        v-if="tabsIndex == 1 && isFlag"
        :tissueComparison="tissueComparison"
        :id="4+'line5'"
        :key="random"
      />
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus";
import ChartLine2 from "./chart_line_2.vue";
import Bar from "./bar";
import LineView from "./lineview";
export default {
  components: {
    ChartLine2,
    Bar,
    LineView,
  },
  props: ["chartType", "date"],
  computed: {},
  data() {
    return {
      random: 0,
      processList: [],
      dateParam: {},
      doctorIds: [],
      doctors: [],
      cascaderProps: {
        multiple: true,
        label: "name",
        value: "code",
        children: "processList",
        checkStrictly: true,
      },
      tabs: [
        {
          name: "柱状图",
          type: 1,
        },
        {
          name: "分布图",
          type: 2,
        },
        {
          name: "趋势图",
          type: 3,
        },
      ],
      tabsIndex: 1,
      select_data: [], // 选中的数据维度权限
      data_tree: [], // 数据维度树
      goodsType: [], // 产品类目列表
      selectClass: [], // 选中的类目
      isLoading: false,
      isFlag: "",
      platformCodes_flag: true, //是否传平台
    };
  },
  created() {
    this.get_platformList();
    this.init();
  },
  methods: {
    get_platformList() {
      //type:1 组织架构 2:产品线
      this.isLoading = true;
      let params = {},
        url = "FINANCE_PLATFORMLIST";
      // 数据请求
      this.axios
        .get(url, "")
        .then((result) => {
          if (result.retCode == "200") {
            this.processList = result.data;
          }else{
           this.$message.error(result.msg);

          }
        })
        .catch(() => {
          //   this.isLoading = false;
        });
    },
    async init() {
      this.startTime = this.date.startTime;
      this.endTime = this.date.endTime;
      this.select_data = [];
      this.querySelect();
    },
    tabClick(val) {
      // console.log(val);
      this.tabsIndex = val + 1;
    },
    // 根据日期判断是月的第几周

    // 查询数据
    querySelect() {
      //type:1 组织架构 2:产品线
      this.isLoading = true;
      // this.$store.dispatch("app/setLoading", true);
      let params = {},
        url = "FINANCE_PLATFORMCOST";
      let platformCodes = [],
        processCodes = [];
      let jgData = [platformCodes, processCodes];
      if (this.selectClass.length > 0) {
        this.selectClass.forEach((element) => {
          if (element.length > 1) {
            this.platformCodes_flag = false;
          }
          element.forEach((child, c_index) => {
            jgData[c_index].push(child);
          });
        });
      }
      params = {
        startMonth: parseInt(this.startTime),
        platformCodes: jgData[0].length > 0 ? [...new Set(jgData[0])] : [],
        processCodes: jgData[1].length > 0 ? [...new Set(jgData[1])] : [],
        endMonth: parseInt(this.endTime),
      };
      if(!this.platformCodes_flag){
        delete params.platformCodes
      }

      // 数据请求
      this.axios
        .post(url, params)
        .then((result) => {
          if(result.retCode == "200"){
             this.tissueComparison = result.data;
          }else{
            this.$message.error(result.msg);
            
          }
         
          this.random = Math.random();
          this.isFlag = true;
          this.isLoading = false;
          // this.$store.dispatch("app/setLoading", false);
          this.$store.dispatch("data/setIncomeProduct", result.data);
        })
        .catch(() => {
          this.isLoading = false;
          // this.$store.dispatch("app/setLoading", false);
        });
    },
    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
  },
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;

  border-bottom-left-radius: none;
  border-bottom-right-radius: none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  margin-top: 24px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .tabs-con {
      min-width: 200px;
      display: flex;
      flex-direction: row;
      li {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-left: 16px;
      }
      .li-active {
        color: #00b54b;
        border-bottom: 2px solid #00b54b;
        text-align: center;
        font-weight: 700;
      }
    }
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>